<template>
  <div>
    <InfoText v-if="noAttributes" class="my-1">this application does not contain any options.</InfoText>
    <div v-else>
      <div class="flex justify-between mb-2">
        <div class="text-blue-600 text-xs">advanced options:</div>
        <ResetAllButton :disabled="noAttributes" @click="resetAttributes" class="reset-button"/>
      </div>

      <FinalForm ref="form" :initialValues="initialValues">
        <div>
          <div v-for="(app) in apps" :key="`${app.appId}`" class="app-options">
            <div v-if="bundleSuite && app.attributes.length > 0" class="text-sm font-600">{{app.appName}}</div>
            <div class="grid grid-flow-row grid-cols-3 gap-x-8">
              <div v-for="(attribute) in app.attributes" :key="`${attribute.id}`" class="pb-2">
                <div class="text-sm mb-2">{{attribute.key}}</div>
                <div class="mb-2 attribute-input">
                  <ToggleField v-if="attribute.isBoolean" :name="`advOptions[${app.appId}][${attribute.id}]`" size="small" />
                  <TextField v-else :name="`advOptions[${app.appId}][${attribute.id}]`" size="xsmall" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </FinalForm>
    </div>
  </div>
</template>

<script>
  import {set, unionBy} from 'lodash-es';
  import {FinalForm} from 'vue-final-form';
  import TextField from "@/components/form/TextField";
  import ToggleField from "@/components/form/ToggleField";
  import InfoText from "@/components/ui/InfoText";
  import ResetAllButton from "@/components/auth/ResetAllButton";
  import {BUNDLE_TYPES} from "@/components/auth/constants";

export default {
  name: "AdvancedLicensingRow",
  components: {
    ToggleField,
    FinalForm,
    InfoText,
    ResetAllButton,
    TextField,
  },
  props: {
    rowData: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number
    },
    options:{
      type: Object,
    }
  },
  data() {
    return {
      initialValues: {},
    }
  },
  computed: {
    apps() {
      const { apps = [] } = this.rowData;
      return apps.map((app) => {
        const attributes = app.attributes.map((item) => ({
          ...item,
          isBoolean: item.allowedValues === 'true|false', // Agreed on BE response.
        }));
        return {
          ...app,
          attributes,
        };
      });
    },
    defaultOptions() {
      return (this.rowData.defaultOptions || []).map((item) => ({...item, value: this.parseAttributeValue(item.value)}));
    },
    bundleSuite() {
      return this.rowData.type === BUNDLE_TYPES.SUITE;
    },
    noAttributes() {
      return this.defaultOptions.length === 0;
    },
  },
  methods: {
    parseAttributeValue(val) {
      // convert 'true', 'false' to boolean for correct behaviour of ToggleInput control
      switch (val) {
        case 'true': return true;
        case 'false': return false;
        default: return val;
      }
    },

    resetAttributes() {
      const form = this.$refs.form.finalForm;
      form.batch(() => {
        this.defaultOptions.forEach(({ appId, attributeId, value }) => {
          form.change(`advOptions[${appId}][${attributeId}]`, value);
        });
      });
    },

    prepareAdvancedOptions() {
      const { options = [] } = this.rowData;

      // advOptions for form initialValues
      const advOptions = options.reduce((acc, { appId, attributeId, value }) => {
        set(acc, [appId, attributeId], this.parseAttributeValue(value));
        return acc;
      }, {});

      this.initialValues = { advOptions };
    },
    getUpdatedOptions({ advOptions = {} }) {
      const options = Object.entries(advOptions)
          .map(([appId, attributes = {}]) =>
              Object.entries(attributes).map(([attributeId, value]) => ({appId, attributeId, value}))
          ).flat(2);

      return unionBy(options, this.rowData.options, 'attributeId');
    },
  },
  created() {
    this.prepareAdvancedOptions();
  },
  mounted() {
    this.formUnsubscribe = this.$refs.form?.finalForm.subscribe(({ values }) => {
      this.rowData.options = this.getUpdatedOptions(values);
    }, {values: true})
  },
  beforeUnmount() {
    if (typeof this.formUnsubscribe === 'function') {
      this.formUnsubscribe();
    }
  }
}
</script>

<style scoped>
  .app-options {
    @apply py-6 border-t border-blue-300;
  }

  .app-options:last-child {
    @apply pb-2;
  }

  .attribute-input {
    max-width: 6.25rem;
  }

  .reset-button {
    @apply text-xs;
  }

  .reset-button :deep(.button-icon) {
    @apply w-2 h-2;
  }
</style>
