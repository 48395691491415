<template>
  <div class="editable-field relative" :class="field_uid">
    <FinalForm
      ref="fieldForm"
      :submit="handleFormSubmit"
      :initialValues="initialValues"
      :class="formClass"
    >
      <template v-slot="props">
        <form @submit="props.handleSubmit">
          <div class="inline-flex items-center" :class="editMode && editClass">
            <div class="relative w-full" ref="field" @keydown="handleKeydown">
              <!-- @slot Field control that has editMode -->
              <slot name="field" :edit-mode="editMode" :form-props="props" :clickHandler="edit"></slot>
            </div>
            <div :class="{'self-start': props.invalid}">
              <SpinLoader v-if="updating" class="ml-1"/>
              <div v-else-if="editMode" class="editable-field-actions flex items-center">
                <slot name="cancel" :cancelHandler="cancel">
                  <IconButton
                    icon="close"
                    type="button"
                    class="flex-shrink-0 ml-2 text-graphite-800"
                    @click.stop="cancel"
                  />
                </slot>
                <slot name="save" :form-props="props">
                  <IconButton
                    icon="checkmark"
                    type="submit"
                    class="flex-shrink-0 ml-1 text-active-500"
                  />
                </slot>
              </div>
              <div v-else-if="withEditControl">
                <IconButton
                  icon="pencil2"
                  class="flex-shrink-0 ml-2 row-hover--visible"
                  @click.stop="edit"
                />
              </div>
            </div>
          </div>
        </form>
      </template>
    </FinalForm>
  </div>
</template>

<script>
  import {FinalForm} from 'vue-final-form';
  import IconButton from "@/components/ui/IconButton";
  import SpinLoader from "@/components/ui/SpinLoader";
  import {isEqual} from "lodash-es";

export default {
  name: 'EditableField',
  props: {
    /**
     * Action to perform on saving changes
     */
    onSubmit: {
      type: Function,
      required: true,
    },

    /**
     * Initial values to fill the form when editMode is on
     */
    initialValues: {
      type: Object,
      default: () => ({})
    },

    /**
     * Classes to apply to the control container inside the form
     */
    editClass: {
      type: String,
    },

    /**
     * Classes to apply to the form in editMode
     */
    formClass: {
      type: String,
    },

    /**
     * If the control should close editMode and return in its text state after saving changes
     */
    closeOnSubmit: {
      type: Boolean,
      default() {
        return true;
      }
    },
    /**
     * Cancel changes when click outside the control. By default submit changes on click outside
     */
    cancelOnOutside: {
      type: Boolean,
      default: false,
    },
    /**
     * Do nothing when click outside the control. By default submit changes on click outside
     */
    noClickOutside: {
      type: Boolean,
      default: false,
    },
    /**
     * Show pencil icon to start editing
     */
    withEditControl: {
      type: Boolean,
      default: true,
    }
  },
  emits: ['edit-mode-change'],
  components: {
    FinalForm,
    IconButton,
    SpinLoader,
  },
  data: function () {
    return {
      editMode: false,
      updating: false,
    };
  },
  computed: {
    field_uid() {
      return 'editable-field-' + this._uid;
    },
    innerState() {
      return this.$refs.fieldForm.formState;
    },
  },
  methods: {
    edit() {
      this.editMode = true;
    },
    cancel() {
      this.editMode = false;
      this.$refs.fieldForm.finalForm.reset();
    },
    handleKeydown(e) {
      if (e.key === 'Escape') {
        this.cancel();
      }
    },
    async handleFormSubmit(values) {
      if (!isEqual(values, this.initialValues)) {
        this.updating = true;
        const result = await this.onSubmit(values);
        this.updating = false;
        if (result) {
          return result;
        }
      }
      if (this.closeOnSubmit) {
        this.editMode = false;
      }
    },
    closeEditMode() {
      this.editMode = false;
    },
    handleOutsideClickEvent(e) {
      if (this.noClickOutside) {
        return;
      }
      if(this.editMode && !this.$el.contains(e.target) && !e.target.closest('.' + this.field_uid)) {
        if (this.cancelOnOutside) {
          this.cancel();
        } else {
          this.$refs.fieldForm.finalForm.submit();
        }
      }
    },
  },
  watch: {
    editMode(val){
      this.$emit('edit-mode-change', val);

      this.$nextTick(() => {
        if (!this.editMode) {
          return;
        }

        const el = this.$refs.field.querySelector('input');

        if (!el) {
          return;
        }

        el.focus();
      });
    },
    initialValues(val) {
      this.$refs.fieldForm?.finalForm.initialize(val);
    }
  },
  created() {
    document.addEventListener('click', this.handleOutsideClickEvent);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleOutsideClickEvent);
  }
};
</script>

<style scoped>
  .editable-field:hover:deep(.editable-field-button) {
    @apply opacity-100;
  }
  .editable-field-actions {
    height: 2.5rem;
    align-self: baseline;
  }
  .editable-field-submit {
    color: var(--highlightColor500);
  }
</style>
