<template>
  <Form
    ref="form"
    :submit="handleSubmit"
    :initialValues="initialValues"
  >
    <div class="form-narrow">
      <div class="form-section-title">main information</div>
        <div v-if="customerId" class="form-row items-end" :style="{display: 'inline-flex', marginBottom: '2rem'}">
            <TextField name="id" label="customer id" :edit-mode="false" />
            <clipboard-button
                class="focus:outline-none copy-id-btn"
                :value="customerId"
            ></clipboard-button>
        </div>
      <div class="form-row">
        <TextField name="name" label="name*" :validate="required"/>
        <TextField name="address.addressLine1" label="HQ address*" :validate="required"/>
      </div>
      <div class="form-row">
        <TextField name="address.city" label="city*" :validate="required"/>
        <StateInput name="address.state" label="state*" :validate="required" />
        <TextField name="address.zip" label="zip*" :validate="required"/>
      </div>
      <Authorize :has-role="['SUPER_ADMIN', 'SUPER_READONLY_ADMIN']">
        <div class="form-section-title mt-10">licensing options</div>
        <div class="text-sm font-600 text-black mb-4">select applications this customer should have access to</div>
        <CustomerLicensing :data="initLicensing" @update="updateLicensing"></CustomerLicensing>
      </Authorize>
      <ModalFooter :footer="footer" :tertiary="cancel"/>
    </div>
  </Form>
</template>

<script>
  import {cloneDeep} from 'lodash-es';
  import ValidatorMixin from '@/components/form/ValidatorMixin';
  import TextField from '@/components/form/TextField';
  import us_states from '@/utils/us_states';
  import Authorize from '@/components/auth/Authorize';
  import StateInput from "@/components/form/StateInput";
  import Form from "@/components/form/Form";
  import ModalFooter from "@/components/ui/modals/ModalFooter";
  import EventBus from "@/utils/EventBus";
  import {INIT_CUSTOMERS_FETCH} from "@/utils/authEvents";
  import CustomerLicensing from "@/views/auth/customers/CustomerLicensing";
  import ClipboardButton from "@/components/auth/list/ClipboardButton";

  export default {
    name: 'CustomerForm',
    components: {
      Authorize,
        ClipboardButton,
      CustomerLicensing,
      ModalFooter,
      Form,
      StateInput,
      TextField,
    },
    mixins: [
      ValidatorMixin,
    ],
    computed: {
      states: function () {
        return us_states.map(state => ({
          value: state.name,
          key: state.abbreviation,
        }));
      },
        customerId() {
          return this.initialValues.id;
        },
      confirm: function () {
        return !!this.$route.params.customerId;
      },
      initLicensing() {
        return cloneDeep(this.initialValues.licenses || []);
      }
    },
    data() {
      return {
        licensing: [],
        footer: {
          primaryButton: 'Save',
          tertiaryButton: 'cancel'
        }
      }
    },
    props: {
      onSubmit: {
        type: Function,
        required: true,
      },
      initialValues: {
        type: Object,
        default: () => ({})
      },
      loading: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    emits: ['close'],
    methods: {
      convertValues({ apps = [], ...values }) {
        return {
          ...values,
          appIds: apps.filter(app => !app.revoked).map(app => app.key),
          licenses: this.licensing,
        };
      },
      handleSubmit(values) {
        return this.onSubmit(this.convertValues(values))
          .then(() => EventBus.emit(INIT_CUSTOMERS_FETCH));
      },
      cancel(e) {
        e.preventDefault();
        this.$emit('close');
      },
      updateLicensing(data) {
        this.licensing = data;
      },
    },
  };
</script>
