<template>
  <button class="clipboard-button relative" type="button" @click.stop="copy">
    <icon name="duplicate" class="h-5 w-4"></icon>
    <div v-if="copied" class="clipboard-button-tooltip absolute bg-white p-2 text-sm rounded shadow">{{message}}</div>
  </button>
</template>

<script>
  import Icon from '@/components/ui/Icon';

  export default {
    name: 'ClipboardButton',
    components: {
      Icon
    },
    props: {
      value: {
        type: String,
        default: ''
      },
    },
    data() {
      return {
        copied: false,
        closeAfter: 1000,
        message: 'copied'
      }
    },
    methods: {
      copy() {
        const element = document.createElement("textarea");
        element.textContent = this.value;
        element.style.position = "fixed";
        document.body.appendChild(element);
        element.select();

        try {
          const successful = document.execCommand('copy');
          this.message = successful ? 'copied' : 'could not copy, sorry';
        }
        catch (ex) {
          this.message = 'could not copy, sorry';
          console.warn("Copy to clipboard failed.", ex);
        }
        finally {
          this.copied = true;
          setTimeout(
              () => {
                this.copied = false;
              },
              this.closeAfter
          );
          document.body.removeChild(element);
        }
      },
    }
  };
</script>

<style scoped>
  .clipboard-button-tooltip {
    top: calc(100% + 0.25rem);
    left: 50%;
    transform: translateX(-50%);
  }
</style>
