<template>
  <div class="spin-loader" v-if="loading"></div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
.spin-loader {
  @apply w-4 h-4 border-2 border-blue-500 rounded-full animate-spin;
  border-bottom-color: transparent;
}
</style>
