<template>
    <div class="text-sm flex items-center">
        <Icon name="info" class="h-4 w-4 inline mr-1" />
        <!-- @slot Content after info icon -->
        <slot />
    </div>
</template>

<script>
    import Icon from '@/components/ui/Icon';

export default {
    name: 'InfoText',
    components: { Icon }
};
</script>

