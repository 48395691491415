<template>
    <div>
        <List
            title="licensing options"
            resource=""
            ref="list"
            :data="licensing"
            :fields="fields"
            track-by="bundleId"
            :detailRow="detailRow"
        >
            <template v-slot:detail-toggler="{record, isOpen, toggleRow}">
                <TogglerButton v-if="record.checked" :isOpen="isOpen" @click="toggleRow"/>
            </template>
        </List>
    </div>
</template>

<script>
import ValidatorMixin from '@/components/form/ValidatorMixin';
import ConfirmationMixin from '@/mixins/ConfirmationMixin';
import List from '@/components/auth/list/List';
import CheckField from '@/components/auth/list/fields/CheckField';
import CommunityNumberField from '@/components/auth/list/fields/CommunityNumberField';
import AdvancedLicensingRow from "@/components/auth/list/detailrows/AdvancedLicensingRow";
import TogglerButton from "@/components/auth/list/TogglerButton";
import { BUNDLE_TYPES } from "@/components/auth/constants";

export default {
    name: 'CustomerLicensing',
    components: {
        List,
        TogglerButton,
    },
    mixins: [ValidatorMixin, ConfirmationMixin],
    props: {
        data: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    emits: ['update'],
    data: function () {
        return {
            bundles: [],
            licensing: [],
            fields: [
                {
                    name: CheckField,
                    title: 'applications list',
                    titleClass: 'text-sm font-normal',
                    switch: {
                        label: 'bundleName',
                        onInput: this.onLicensingInput,
                        onChange: this.onLicensingCheck,
                    },
                },
                {
                    name: CommunityNumberField,
                    title: 'number of communities',
                    titleClass: 'text-sm font-normal',
                    width: '32%',
                },
                {
                    name: 'detail-toggler',
                    title: 'advanced options',
                    titleClass: 'text-sm font-normal text-right',
                    dataClass: 'text-right',
                    width: '20%',
                },
            ],
            detailRow: AdvancedLicensingRow,
        };
    },
    computed: {
        initialLicensingMap() {
            return this.data.reduce((acc, item) => ({
                ...acc,
                [item.bundleId]: item,
            }), {});
        },
    },
    watch: {
        data: {
            handler: function () {
                // update licensing with customer checked settings on init
                this.licensing = this.prepareInitialLicensing();
            },
        },
        bundles: {
            handler: function () {
                this.licensing = this.prepareInitialLicensing();
            },
            deep: true,
        },
        licensing: {
            handler: function () {
                const licensing = this.licensing
                    .filter(l => l.checked)
                    .map(({bundleId, numberOfCommunities, options: attributes}) => ({
                        bundleId, numberOfCommunities, attributes,
                    }));
                this.$emit('update', licensing);
            },
            deep: true,
        },
    },
    methods: {
        prepareInitialLicensing() {
            return this.bundles.map(({id, name, apps, type, defaultOptions}) => ({
                bundleId: id,
                bundleName: name,
                checked: Boolean(this.initialLicensingMap[id]),
                numberOfCommunities: this.initialLicensingMap[id]?.numberOfCommunities || 1,
                options: this.initialLicensingMap[id]?.options || defaultOptions,
                apps,
                defaultOptions,
                type,
            }));
        },
        onLicensingInput(value, {data, update}) {
            if (value) {
                update(value);
                return;
            }

            const checkedOnInit = Boolean(this.initialLicensingMap[data.bundleId]);

            if (checkedOnInit) {
                this.requestConfirmation({
                    confirmationMessage: `You’re removing license for ${data.bundleName}. After saving changes this will lead to revocation of access for staff users in all customer communities. The action can’t be undone. Restoration of access will have to be made by admin manually for each staff user.`,
                    confirmBtnText: 'proceed with revoking',
                    cancelBtnText: 'cancel',
                    confirmationType: 'warning',
                }).then((confirmed) => {
                    if (confirmed) {
                        update(value);
                    }
                });
            } else {
                update(value);
            }
        },
        onLicensingCheck(value, {data}) {
            const vuetable = this.$refs.list?.$refs.vuetable;

            if (!value && vuetable && vuetable.isVisibleDetailRow(data.bundleId)) {
                vuetable.hideDetailRow(data.bundleId);
            }
            return true;
        },
    },
    mounted() {
        this.$authDataProvider.getList('bundles', {
            page: 0,
            size: 999,
            nonDeleted: true,
        }).then(({content}) => {
            this.bundles = content
                .filter((bundle) => !(bundle.type === BUNDLE_TYPES.STAND_ALONE && bundle.apps[0].isCustomerUserAgnostic))
                .map((bundle) => {
                    // default advanced options for a bundle
                    const defaultOptions = bundle.apps.reduce((acc, {appId, attributes}) => {
                        const options = attributes.map((item) => ({
                            appId,
                            attributeId: item.id,
                            value: item.currentValue ?? item.defaultValue,
                        }));
                        return [...acc, ...options];
                    }, []);

                    return {
                        ...bundle,
                        defaultOptions,
                    };
                });
        });
    },
};
</script>
