<template>
  <th
    v-if="isHeader"
    class="vuetable-th-component-communityNumber"
    v-html="title"
  />
  <td v-else class="vuetable-td-component-communityNumber">
    <editable-field
      v-if="checked"
      :initialValues="{ value: rowData.numberOfCommunities }"
      editClass="mb-1"
      :on-submit="update"
    >
      <template v-slot:field="{ editMode, formProps }">
        <TextField
          name="value"
          :editMode="editMode"
          textClass="text-sm"
          placeholder=""
          :validate="composeValidators(required, number, minValue(1))"
          :clearable="editMode && Boolean(formProps.values.value)"
        />
      </template>
    </editable-field>
  </td>
</template>

<script>
  import VuetableFieldMixin from 'vue3-vuetable/src/components/VuetableFieldMixin.vue';
  import ValidatorMixin from '@/components/form/ValidatorMixin';
  import TextField from "@/components/form/TextField";
  import EditableField from "@/components/ui/inlineediting/EditableField";

  export default {
    name: 'CommunityNumberField',
    components: { TextField, EditableField},
    mixins: [VuetableFieldMixin, ValidatorMixin],
    computed: {
      checked() {
        return this.rowData.checked;
      },
    },
    methods: {
      update({ value }) {
        this.rowData.numberOfCommunities = value;
      },
    },
  };
</script>
<style scoped>

</style>
