<template>
  <button class="p-1 focus:outline-none" type="button" @click="$emit('click')">
    <icon class="w-4 h-4 text-gray-300" :class="{'transform rotate-180': isOpen}" name="chevronDown" />
  </button>
</template>

<script>
  import Icon from "@/components/ui/Icon";

  export default {
    name: "TogglerButton",
    components: {Icon},
    props: {
      isOpen: {
        type: Boolean,
        required: true,
        default: false,
      },
    },
    emits: ['click'],
  }
</script>
