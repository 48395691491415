<template>
  <button
    type="button"
    class="text-sm flex items-center font-500"
    :class="[disabled ? 'text-gray-300 cursor-auto' : 'text-blue-500']"
    :disabled="disabled"
    @click="handleClick"
  >
    <Icon name="close" class="button-icon w-3 h-3 mr-1" />reset all
  </button>
</template>

<script>
  import Icon from '@/components/ui/Icon';

  export default {
    name: 'ResetAllButton',
    components: { Icon },
    props: {
      /**
       * If the control should be disabled
       */
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['click'],
    methods: {
      handleClick() {
        /**
         * Emitted when the button is clicked
         */
        this.$emit('click');
      }
    }
  };
</script>
<style scoped>

</style>
