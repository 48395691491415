<template>
  <FinalField
    ref="field"
    :name="name"
    :validate="validate"
  >
    <template v-slot="props">
      <div :class="containerClass">

        <label v-if="label" :for="name">{{ label }}</label>

        <toggle-input
          :modelValue="props.value"
          :name="name"
          @update:modelValue="props.change"
          v-bind="$attrs"
        />

        <span
          v-if="props.meta.error && props.meta.touched"
          class="form-hint form-error"
        >
          {{ props.meta.error }}
        </span>
      </div>
    </template>
  </FinalField>
</template>

<script>
  import {FinalField} from 'vue-final-form';
  import ToggleInput from "@/components/ui/ToggleInput";

  export default {
    name: "ToggleField",
    components: {
      FinalField,
      ToggleInput,
    },
    props: {
      name: {
        type: String,
      },
      label: {
        type: String,
      },
      validate: {
        type: [Function, Array],
        required: false,
      },
      containerClass: {
        type: String,
        default: 'form-col'
      }
    },
  }
</script>
