<script>
  import { h } from 'vue'
  import { mapGetters } from 'vuex';

  export default {
    name: 'Authorize',
    props: {
      hasRole: {
        type: [String, Array],
      }
    },
    computed: {
      ...mapGetters({
        role: 'auth/userRole'
      }),
      authorized: function () {
        return [].concat(this.hasRole)
          .includes(this.role);
      }
    },
    render: function () {
      return this.authorized ? h('div', { class: 'auth-wrapper' }, this.$slots.default()) : null;
    }
  };
</script>

<style scoped>
  .auth-wrapper {
    display: contents;
  }
</style>
