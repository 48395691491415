<template>
    <th
        v-if="isHeader"
        class="vuetable-th-component-checkfield"
        v-html="title"
    />
    <td v-else class="vuetable-td-component-checkfield" @click="$emit('click')">
        <div :class="containerClass">
            <div v-if="!readonly">
                <Tooltip v-if="disabled && Boolean(disabledReason)" :text="disabledReason">
                    <template v-slot:label>
                        <checkbox :label="label" :value="check" disabled />
                    </template>
                </Tooltip>

                <checkbox
                    v-else
                    :label="label"
                    :value="check"
                    :disabled="disabled"
                    :onInput="handleInput"
                    @change="handleChange"
                />
            </div>

            <div v-else-if="check" class="flex items-center">
                <span class="w-6 h-6 text-blue-500"><icon name="tick"></icon></span>
                <div class="select-none" v-if="label" v-html="label"></div>
            </div>

            <div class="relative">
                <div class="flex absolute left-0">
                    <Tooltip v-if="Boolean(info)" icon="info" :text="info"/>
                </div>
            </div>
        </div>
    </td>
</template>

<script>
import { get } from 'lodash-es';
import VuetableFieldMixin from 'vue3-vuetable/src/components/VuetableFieldMixin.vue';
import Checkbox from "@/components/ui/Checkbox";
import Icon from "@/components/ui/Icon";
import Tooltip from "@/components/ui/Tooltip";

export default {
    name: 'CheckField',
    components: {
        Checkbox,
        Icon,
        Tooltip,
    },
    mixins: [VuetableFieldMixin],
    emits: ['click'],
    computed: {
        label() {
            const label = this.rowField.switch?.label;
            return this.rowData[label] || '';
        },
        containerClass() {
            return this.rowField.switch?.containerClass;
        },
        disabled() {
            const disabled = this.rowField.switch?.disabled;
            return typeof disabled === 'function' ? disabled(this.rowData) : disabled;
        },
        disabledReason() {
            const disabledReason = this.rowField.switch?.disabledReason;
            return typeof disabledReason === 'function' ? disabledReason(this.rowData) : disabledReason;
        },
        info() {
            const info = this.rowField.switch?.info;
            return typeof info === 'function' ? info(this.rowData) : info;
        },
        fieldName() {
            return this.rowField.switch?.fieldName || 'checked';
        },
        check() {
            return Boolean(get(this.rowData, this.fieldName));
        },
        readonly() {
            return this.rowField.switch?.readonly;
        },
        handleInput() {
            const onInput = this.rowField.switch?.onInput;

            if (typeof onInput === 'function') {
                return (v) => {
                    onInput(v, {data: this.rowData, index: this.rowIndex, update: this.updateValue});
                }
            }

            return (v) => {
                this.updateValue(v);
            }
        },
    },
    methods: {
        updateValue(value) {
            this.rowData[this.fieldName] = value;
        },
        handleChange(value) {
            const onChange = this.rowField.switch?.onChange;

            if (typeof onChange === 'function') {
                const preventDataChange = onChange(value, {data: this.rowData, index: this.rowIndex});

                if (preventDataChange) {
                    return;
                }
            }

            this.updateValue(value);
        },
    },
};
</script>
